import React from "react"
import Image from "../image"

const About = () => (
  <section id="about">
    <div className="container flex-center">
      <div className="header">ABOUT ME</div>
      <div className="content">
        <div className="about-box">
          <div className="about-photo">{Image().photo}</div>
          <div className="about-text">
            <p>
              I am a <span className="bold">web developer</span> based in
              Gdynia, Poland.
            </p>

            <p>
              Currently in roles of{" "}
              <span className="bold">Head Front-End developer</span> of{" "}
              <span className="bold">SmartFactor</span> and occasional{" "}
              <span className="bold">technical mentor</span> at{" "}
              <span className="bold">hackathons</span> besides of that I'm
              always up for{" "}
              <span className="bold">freelance and side projects</span> in the
              spare time. Programming has been my{" "}
              <span className="bold">passion</span> for years - constantly{" "}
              <span className="bold">
                seeking knowledge and sharpening the skill set
              </span>{" "}
              to keep my workshop up to the changing trends.
            </p>

            <div className="mobile-hidden">
              <p>
                <span className="bold">Focus</span> on broadening the mindset,
                interests and <span className="bold">staying curious</span>{" "}
                about fast-paced world of the web development are - in my
                opinion - crucial to staying{" "}
                <span className="bold">creative and up-to-date</span> in this
                environment - thats why I <span className="bold">enjoy</span>{" "}
                taking part in technical discussions, attending to meetups /
                conferences and sharing experiences with others.
              </p>
            </div>
            <p>
              My current toolset consists of{" "}
              <span className="bold">
                React / React Native + Redux, Angular, Gatsby, TypeScript, SCSS,
                CSS-in-JS, Webpack, Firebase
              </span>{" "}
              along with libraries related to them, also for a long time I have
              been connected to geospatial tools like{" "}
              <span className="bold">OpenLayers and MapBox</span> and I'm
              keeping close to them.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default About;
