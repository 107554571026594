import React from 'react';

const Home = () => (
  <section id="home" className="flex-center">
    <div id="home-text" className="flex-center">
      <p>It's good to see <span className="highlight">You</span>!</p>
      <p>I'm <span className="highlight">Paweł</span>, I create Internet.</p>
    </div>
  </section>
);

export default Home;
