import React from "react"

const Values = () => (
  <section id="values">
    <div className="container flex-center">
      <div className="header">HOW DO I WORK</div>
      <div className="content">
        <div className="values-box">
          <div className="values-icon">
            <span>1</span>
          </div>
          <div className="values-content">
            <div className="values-header">Research</div>
            <div className="values-text">
              The process starts with a{" "}
              <span className="bold">conversation</span> to help us clearly
              identify all the{" "}
              <span className="bold">needs and requirements</span> of the
              project. Together, we will collect the provisions that will serve
              me in the next step.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <span>2</span>
          </div>
          <div className="values-content">
            <div className="values-header">Analysis</div>
            <div className="values-text">
              I translate the collected information into a{" "}
              <span className="bold">
                detailed business and technical analysis
              </span>{" "}
              that will facilitate the <span className="bold">validation</span>{" "}
              of Your solution. In this step you will learn what steps are
              needed to be taken to{" "}
              <span className="bold">solve the problem</span> and we will
              determine my ability to help You.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <span>3</span>
          </div>
          <div className="values-content">
            <div className="values-header">Design</div>
            <div className="values-text">
              A thorough understanding of the subject will allow me to{" "}
              <span className="bold">design Your solution</span> - both visually
              and functionally. As a result of this step You will receive an
              <span className="bold">outline of the product</span> to which you
              will be able to report potential remarks.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <span>4</span>
          </div>
          <div className="values-content">
            <div className="values-header">Development</div>
            <div className="values-text">
              In this step, <span className="bold">the magic happens</span>{" "}
              (only the chosen ones can understand it)... Its result is the
              creation of a <span className="bold">complete solution</span> that
              was previously written on the paper in above phases.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <span>5</span>
          </div>
          <div className="values-content">
            <div className="values-header">Maintenance</div>
            <div className="values-text">
              Providing a complete solution is usually the end of the process…
              but not in our case! If any questions or problems occur, I offer
              my help to <span className="bold">make sure You are happy</span>{" "}
              with the product You was handed.
            </div>
          </div>
        </div>

        <div className="values-box">
          <div className="values-icon">
            <span className="asterisk">*</span>
          </div>
          <div className="values-content">
            <div className="values-text">
              Keep in mind that the basis of each step is{" "}
              <span className="bold">mutual communication</span>
              between us to ensure that everything goes according to the initial
              provisions. In my opinion, the{" "}
              <span className="bold">commitment</span> of both parties is the{" "}
              <span className="bold">key to success</span> in the ongoing
              venture.{" "}
              <span className="bold">
                We are here to solve problems, not to create them.
              </span>
              <p>
                After the project ends I expect{" "}
                <span className="bold">Your honest feedback</span> about whole
                process we have been through. No matter if its positive or not -
                after all it helps me to draw conclusions and take steps in{" "}
                <span className="bold">
                  improving myself to provide higher quality services
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Values;
