import React from 'react';

const WhatImGoodAt = () => (
  <section id="what-im-good-at">
    <div className="container flex-center">
      <div className="header">
        WHAT IM GOOD AT
      </div>
      <div className="content">

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-pencil"></i>
          </div>
          <div className="wiga-box-header">
            Design
          </div>
          <div className="wiga-box-text">
            <span className="bold">Have an idea</span> for a product but don’t have anyone to <span
            className="bold">make
                                it material</span>? I can help You by making a technical <span
            className="bold">analysis</span>
            and <span className="bold">designing</span> the visual mockups
            of the <span className="bold">solution</span>.
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-browser"></i>
          </div>
          <div className="wiga-box-header">
            Static websites
          </div>
          <div className="wiga-box-text">
            Want to <span
            className="bold">show your presence in the world of the Internet</span> but have
            no clue how to do it? Together we can build a landing page, brochure or bussines website
            <span className="bold">tailored to Your needs!</span>
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-cogs"></i>
          </div>
          <div className="wiga-box-header">
            Web applications
          </div>
          <div className="wiga-box-text">
            Need someone to create a <span
            className="bold">complex single-page-application</span> for You
            or tinker around in already existing one? Here I am!
          </div>
        </div>

        <div className="wiga-box">
          <div className="wiga-box-icon">
            <i className="fal fa-life-ring"></i>
          </div>
          <div className="wiga-box-header">
            Support
          </div>
          <div className="wiga-box-text">
            Already have an existing team but still <span className="bold">need someone to help pushing
                                through</span>? Contact me and we will see if I fit into Your needs
          </div>
        </div>

      </div>
    </div>
  </section>
);

export default WhatImGoodAt;
