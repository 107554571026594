import React from "react"

import Layout from "../components/layout"
import SEO from "../components/en/seo"
import Logo from "../components/logo"
import Home from "../components/en/home"
import About from "../components/en/about"
import WhatImGoodAt from "../components/en/what-im-good-at"
import Values from "../components/en/values"
import Footer from "../components/footer"

const ENIndexPage = () => (
  <Layout>
    <SEO title="Paweł Zdralewicz - Portfolio" />
    <Logo />
    <Home />
    <About />
    <WhatImGoodAt />
    <Values />
    <Footer />
  </Layout>
)

export default ENIndexPage
